
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import { AnalyticsBudgetInfo, AnalyticsKpiInterface } from '../../../types/overview_types'
import { calcTrend, stringTemplateYDA, TREND_ICON_COLOR } from '@/components/SurcoucheV2/surcoucheV2utils'
import { isNotNullOrUndefined } from '../../../utils/commonUtils'
import { KpiValue } from '../../../types/brief_enum'

const PERCENT_OF_MAX = 0.9
const KPI_SHOW_FLIGHT_PROGRESS = [KpiValue.reach, KpiValue.reach_and_frequency]

export default defineComponent({
  name: 'KpiLastWeekHealth',
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
      hoverKpiBilling: null,
      trendingPercent: null,
      trendIcon: '',
      percent: null,
      isBold: false,
      trendIconColor: '',
      isOverTenPercent: true
    }
  },
  methods: {
    round (value: number, precision = 2) {
      if (!value) {
        return value
      }
      return this.$commonUtils.roundNum(value, precision)
    },
    getKpiBilling () {
      if (!this.io.analytics_kpi_interfaces || this.io.analytics_kpi_interfaces.length === 0) {
        this.setHoverKpiBilling(null)
        return ''
      }

      const kpiInterface = this.io.analytics_kpi_interfaces
      const kpiLast7: AnalyticsKpiInterface = kpiInterface.find((elem) => elem.period === 'last_7_days')
      const yesterday: AnalyticsKpiInterface = kpiInterface.find((elem) => elem.period === 'yesterday')
      if (kpiLast7.kpi_to_optimize !== this.io.KPI_to_optimize || yesterday.kpi_to_optimize !== this.io.KPI_to_optimize) {
        this.setHoverKpiBilling(null)
        return `${this.io.KPI_to_optimize}: N/A`
      }

      if (KPI_SHOW_FLIGHT_PROGRESS.includes(this.io.KPI_to_optimize)) {
        return this.getKpiBillingProgress()
      }
      const target: number = this.round(this.io.obj_watcher.KPI_to_optimize.value) ?? null
      let trend: string = '<'
      let toReturn = `${this.io.KPI_to_optimize}: ${this.round(kpiLast7.kpi_to_optimize_value)}`
      if (target) {
        if (target - kpiLast7.kpi_to_optimize_value > 0) {
          trend = '<'
        } else {
          trend = '>'
        }
        toReturn += ` ${trend} ${target}`
      }

      this.percent = calcTrend(yesterday.kpi_to_optimize_value, kpiLast7.kpi_to_optimize_value)
      if (isNotNullOrUndefined(this.percent)) {
        this.trendingPercent = stringTemplateYDA(this.percent)
        if (this.percent > 0) {
          this.trendIcon = 'trending-up'
        } else if (this.percent < 0) {
          this.trendIcon = 'trending-down'
        }
      }
      this.setKpiBillingStyles(kpiLast7, target)
      this.setHoverKpiBilling(kpiLast7)
      return toReturn
    },
    getKpiBillingProgress () {
      this.trendIcon = ''
      this.percent = null
      this.trendingPercent = null
      const kpiFlight = this.io.analytics_kpi_interfaces.find((elem) => elem.period === 'flight')
      const budgetInfo = this.io.analytics_budget_info[0] // only one element in the array
      if (kpiFlight && budgetInfo) {
        const target = this.io.obj_watcher.KPI_to_optimize.value ?? null
        if (!target) {
          this.setHoverKpiBilling(null)
          return `${this.io.KPI_to_optimize}: ${this.round(kpiFlight.kpi_to_optimize_value)}`
        }
        const reachPercent = kpiFlight.kpi_to_optimize_value / target
        const flightTimePercent = this.getFlightTimePercent(budgetInfo)
        let trend = '<'
        if (reachPercent > flightTimePercent) {
          trend = '>'
        }
        this.setHoverKpiBillingString(`Achieved reach vs days passed in the flight, updated on ${new Date(kpiFlight.computation_date_utc).toDateString()}`)
        return `${this.io.KPI_to_optimize}: ${this.round(reachPercent * 100)}% ${trend} ${this.round(flightTimePercent * 100)}%`
      } else {
        return ''
      }
    },
    getFlightTimePercent (budgetInfo: AnalyticsBudgetInfo) {
      const flightStart = new Date(budgetInfo.flight_start_utc)
      const flightEnd = new Date(budgetInfo.flight_end_utc)
      const flightLength = flightEnd.getTime() - flightStart.getTime()
      return Math.min(((new Date().getTime() - flightStart.getTime()) / flightLength), 1) // cap at 100%
    },
    setHoverKpiBilling (kpiLast7: AnalyticsKpiInterface) {
      if (kpiLast7 !== null) {
        const startDate: string = new Date(kpiLast7.start_period_tz).toDateString()
        const endDate: string = new Date(kpiLast7.end_period_tz).toDateString()
        this.hoverKpiBilling = `Last 7 days: from ${startDate} to ${endDate} (IO tz)`
      } else {
        this.hoverKpiBilling = null
      }
    },
    setHoverKpiBillingString (text: string) {
      this.hoverKpiBilling = text
    },
    setKpiBillingStyles (kpiLast7: AnalyticsKpiInterface, target: number) {
      if (this.io.obj_watcher.KPI_to_optimize.KPI in ['CTR', 'VTR']) {
        this.isBold = isNotNullOrUndefined(target) && (PERCENT_OF_MAX * kpiLast7.kpi_to_optimize_value) < target
        this.trendIconColor = this.percent < -10 ? TREND_ICON_COLOR : ''
        this.isOverTenPercent = this.percent < -10
      } else {
        this.isBold = isNotNullOrUndefined(target) && (PERCENT_OF_MAX * kpiLast7.kpi_to_optimize_value) > target
        this.trendIconColor = this.percent > 10 ? TREND_ICON_COLOR : ''
        this.isOverTenPercent = this.percent > 10
      }
    }
  },
  computed: {
    showTrend (): boolean {
      return this.trendingPercent !== null
    }
  }
})
