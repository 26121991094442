
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import { AnalyticsPacingStatus } from '../../../types/overview_types'
const OVER_LIMIT_BUDGET = 0.1
const LIMIT_BLOCKED_THAN_REALIZED = 0.33
const LIMIT_SPENDING_TIME = 0.75
const OVER_LIMIT_BUDGET_2 = 0.2
const MAX_DAYS_SINCE_PACING_DATE = 2

export default defineComponent({
  name: 'OverpacedHealth',
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
      hoverPacing: ''
    }
  },
  methods: {
    round (value: number, precision = 4) {
      if (!value) {
        return value
      }
      return this.$commonUtils.roundNum(value, precision)
    },
    getPacingYesterday () {
      if (!this.io.analytics_pacing_status || this.io.analytics_pacing_status.length === 0) {
        this.setHoverPacing(null)
        return ''
      }
      const pacing = this.io.analytics_pacing_status.reduce(
        (acc: AnalyticsPacingStatus, curr: AnalyticsPacingStatus) => {
          const currDate = new Date(curr.day_tz)
          const accDate = new Date(acc.day_tz)
          if (accDate < currDate) {
            return curr
          } else {
            return acc
          }
        }
      )
      const dayInMilliseconds = 1000 * 60 * 60 * 24
      const pacingTimestamp = new Date(pacing.day_tz).getTime()
      const todayTimestamp = new Date().getTime()
      const daysSincePacingDate = Math.floor((todayTimestamp - pacingTimestamp) / dayInMilliseconds)
      if (daysSincePacingDate > MAX_DAYS_SINCE_PACING_DATE) {
        this.setHoverPacing(null)
        return ''
      }
      this.setHoverPacing(pacing)
      return this.setPacingMessage(pacing)
    },
    setHoverPacing (pacing: AnalyticsPacingStatus | null) {
      if (pacing) {
        const dayTz = new Date(pacing.day_tz).toDateString()
        this.hoverPacing = `Date on ${dayTz} (IO tz)`
      } else {
        this.hoverPacing = ''
      }
    },
    calcPercentSpentYesterday (ioSatu: number) {
      return this.round((1 - ioSatu) * 100, 0)
    },
    setPacingMessage (pacing: AnalyticsPacingStatus) {
      if (pacing.io_satu < OVER_LIMIT_BUDGET && (pacing.io_pct_oversatu > LIMIT_BLOCKED_THAN_REALIZED ||
        pacing.io_expected_pct_hour < LIMIT_SPENDING_TIME)) {
        return '<b>Overpaced yesterday</b>'
      } else if (pacing.io_satu > OVER_LIMIT_BUDGET_2) {
        return `<b>${this.calcPercentSpentYesterday(pacing.io_satu)} % spent yesterday</b>`
      } else {
        return `${this.calcPercentSpentYesterday(pacing.io_satu)} % spent yesterday`
      }
    }
  }
})
